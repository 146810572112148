$(document).foundation();
// Set a cookie, get a cookie
    $(document).ready(function() { 
        if (Cookies.get("hasvisited") == null) {
        $('#ip-top-alert').addClass('show');
        Cookies.set('hasvisited', 'yes', { expires: 7 });
       }
    });
// Video Init
      var videoplayersettings = {
        autoplay : "off",
        videoWidth : 1100,
        videoHeight : 800,
        responsive_ratio: "detect",
        constrols_out_opacity : 0.1,
        constrols_normal_opacity : 0.8,
        design_scrubbarWidth : -201,
        design_background_offsetw : 0,
        settings_hideControls : "off"
      };
      $("#ip-video-player").vGallery({
        menuSpace: 20,
        randomise:"off",
        autoplay :"off",
        autoplayNext : "on",
        menuitem_width:400,
        menuitem_height:120,
        menuitem_space: 0,
        menu_position:'right',
        transition_type:"fade",
        design_skin: 'skin_white',
        videoplayersettings : videoplayersettings,
        logo: "assets/images/logos/ip-logo-horiz-dark.svg",
        responsive: "on",
        settings_menu_overlay: 'on'
      });
// Pizza Init
  Pizza.init();
// Photoswipe Init
(function($) {
    var $pswp = $('.pswp')[0];
    var image = [];

    $('.ip-photo-gallery').each( function() {
        var $pic     = $(this),
            getItems = function() {
                var items = [];
                $pic.find('a').each(function() {
                    var $href   = $(this).attr('href'),
                        $size   = $(this).data('size').split('x'),
                        $width  = $size[0],
                        $height = $size[1];

                    var item = {
                        src : $href,
                        w   : $width,
                        h   : $height
                    }

                    items.push(item);
                });
                return items;
            }

        var items = getItems();

        $.each(items, function(index, value) {
            image[index]     = new Image();
            image[index].src = value['src'];
        });

        $pic.on('click', 'figure', function(event) {
            event.preventDefault();
            
            var $index = $(this).index();
            var options = {
                index: $index,
                bgOpacity: 0.7,
                showHideOpacity: true,
                preload: [1,1]
            }

            var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
            lightBox.init();
        });
    });
})(jQuery);